import { CssBaseline, ThemeProvider } from '@mui/material'
import FeedbackDialog from 'components/Modals/FeedbackDialog/FeedbackDialog'
import { UserSettingsContext, fetchSettings } from 'context/UserSettingsContext'
import i18next from "i18next"
import React, { Suspense, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from "react-i18next"
import 'react-perfect-scrollbar/dist/css/styles.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from "react-router-dom"
import { RecoilRoot } from 'recoil'
import RouterRoot from './components/RouterRoot'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import constants from './services/constants'
import theme from './theme'
import common_de from "./translations/de/common.json"
import common_en from "./translations/en/common.json"
import common_es from "./translations/es/common.json"
import common_jp from "./translations/jp/common.json"

const queryClient = new QueryClient()

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: localStorage.getItem(constants.languageStorage) || 'en',// language to use
  resources: {
    en: { common: common_en },
    de: { common: common_de },
    es: { common: common_es },
    jp: { common: common_jp },
  },
});

function App() {
  const [settings, setSettings] = useState(null);
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>


        <CookiesProvider>
          <UserSettingsContext.Provider value={{ settings: settings, setSettings: setSettings, fetchSettings: fetchSettings }}>
            <Suspense fallback="loading...">
              <I18nextProvider i18n={i18next}>
                <RecoilRoot>
                  <Router>
                    <ThemeProvider theme={theme} >
                      <CssBaseline />
                      <RouterRoot />
                      <FeedbackDialog />
                    </ThemeProvider>
                  </Router>
                </RecoilRoot>
              </I18nextProvider>
            </Suspense>
          </UserSettingsContext.Provider>
        </CookiesProvider>
      </QueryClientProvider>
    </React.StrictMode >
  );
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
