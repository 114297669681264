import { faCompressArrowsAlt, faCube, faCubes, faEye, faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CropFreeIcon from '@mui/icons-material/CropFree'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import styles from './HierarchyPanel.module.scss'

const InvisibilityIcon = () => {
    return (
        <img className={styles['iconButton']} src={process.env.PUBLIC_URL + '/icons/eye-closed.png'} alt="Icon for invisibility" height={'auto'} width={18} />
    )
}

const NonGeomIcon = () => {
    return (
        <Tooltip title="This node does not contain any geometry">
            <CropFreeIcon className={styles['class-icon']} sx={{ width: '20px', height: 'auto' }} />
        </Tooltip>
    )
}

export default function NodeLabel({ node, onVisibility, onHover, searched, onIsolation, isolatedHandle }): JSX.Element {
    const handleIsolation = (e) => {
        e.stopPropagation()
        onIsolation(node, isolatedHandle !== node.dbId)
    }

    const highlightSearchedTerm = (sentence: string) => {
        if (searched?.length > 0) {
            const parts = sentence.split(new RegExp(`(${searched})`, 'gi'))
            return parts.filter((part) => part.length > 0).map((part, index) => (
                <span key={index} className={part.toLowerCase() === searched.toLowerCase() ? styles['searched-term'] : ''}>
                    {part}
                </span>
            ))
        } else {
            return sentence
        }
    }

    const getNodeIcon = () => {
        if (node.level === 0) {
            // Base level
            return <FontAwesomeIcon icon={faHouseChimney} fontSize={18} />;
        } else if (!node.hasGeometry) {
            // No geometry
            return <NonGeomIcon />;
        } else if (node.children?.length > 0) {
            // Has child entities
            return <FontAwesomeIcon icon={faCubes} fontSize={18} />;
        } else {
            // Solo entity
            return <FontAwesomeIcon icon={faCube} fontSize={14} />;
        }
    }

    return (
        <Box className={`${styles['node-label-container']} ${!node.visible && styles['node-label-container-invisible']} ${!node.hasGeometry && styles['node-label-container-no-geo']}`}>
            <Box className={styles['node-label-wrapper']}>
                {getNodeIcon()}
                <Typography classes={{ root: styles['node-label'] }}>{highlightSearchedTerm(node.name)}</Typography>
            </Box>
            <Box className={styles['action-buttons']}>
                <Tooltip title="Isolate">
                    <IconButton className={styles['icon-button']} onClick={(event: any) => handleIsolation(event)}>
                        <FontAwesomeIcon icon={faCompressArrowsAlt} fontSize={12} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Toggle Visibility">
                    <IconButton className={styles['visibility-button']} onClick={onVisibility}>
                        {node.visible ? <FontAwesomeIcon icon={faEye} fontSize={16} /> : <InvisibilityIcon />}
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}
