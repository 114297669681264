import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderIcon from '@mui/icons-material/Folder';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import publicProjectPoliciesAtom, { IPublicProjectPolicy } from 'atoms/publicProjectPoliciesAtom';
import SettingsDialog from 'components/ModelViewer/ProjectSettings/SettingsDialog';
import { Indicator } from 'components/Shared/ButtonGroupIndicator/ButtonGroupIndicator';
import usePermissions from 'hooks/usePermissions';
import useSession from 'hooks/useSession';
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import HierarchyPanel from '../ConsolePanel/HierarchyPanel';
import DocumentsPanel from '../Documents/DocumentsPanel';
import ModelsPanel from '../ModelsPanel/ModelsPanel';
import SearchPanel from '../SearchPanel';
import { LeftSideToolBarProps } from '../ToolBarProps';
import styles from './ModelViewerToolbars.module.scss';

enum LeftSideBarPanels {
    NONE = 'none',
    CONSOLE = 'console',
    MODELS = 'models',
    DOCUMENTS = 'documents',
    SEARCH = 'search',
}

export default function LeftSideBar(props: LeftSideToolBarProps) {
    const { t } = useTranslation('common');
    const { modelviewer: viewer } = props;
    const [, setSearchParams] = useSearchParams();
    const location = useLocation();
    const anchor = useRef(null);
    const { projectId } = props;
    const buttonGroup = useRef(null);
    const [currentPanel, setCurrentPanel] = useState<LeftSideBarPanels>(LeftSideBarPanels.NONE);
    const [projectSettingsToggleOn, setProjectSettingsToggle] = React.useState(false);
    const [visualEntities, setVisualEntities] = useState([]);
    const [documentsDisabled, setDocumentsDisabled] = useState(props.isLoading);
    const { isGuest } = useSession();
    const projectsPolicyStatements: IPublicProjectPolicy = useRecoilValue(publicProjectPoliciesAtom);
    const { hasAccess } = usePermissions(['document.read', 'document.edit'], projectId);

    useEffect(() => {
        if (!projectsPolicyStatements || props.isLoading) {
            return;
        }

        if (!projectsPolicyStatements.isPublic) {
            //private project check team user role permissions 
            setDocumentsDisabled(!hasAccess);
            return;
        }
        //public project use project policy role permissions
        const isGuestUser = isGuest();
        const canReadPublicDocumentsPolicies = projectsPolicyStatements?.policies?.find(
            (policy) => policy.resource === 'document' && policy.name === 'read'
        );
        const canReadPublicDocuments = canReadPublicDocumentsPolicies === undefined && isGuestUser; //only applies to public users.
        setDocumentsDisabled(canReadPublicDocuments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsPolicyStatements, hasAccess]);

    const handlePanelClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('lpanel');
    };

    const onVisibilityToggle = (entities, visible) => {
        if (!viewer) {
            return;
        }

        if (!visible) {
            viewer.ShowEntity(entities);
        } else {
            viewer.HideEntity(entities);
        }
    };

    const onNodeHover = (entities, hovered) => {
        if (!viewer) {
            return;
        }

        viewer.hoverEntity(entities, hovered);
    };

    const onSelection = (entities, root) => {
        if (!viewer) {
            return;
        }

        let selection = viewer.currentSelection;
        let alreadySelected = entities.every((e) => selection.includes(e));

        if (alreadySelected) {
            viewer.setSelection(entities, false, false);
        } else {
            viewer.setSelection(entities, true, true);
        }

        viewer.setSelectionTool();
    };

    const handleTab = (e: SyntheticEvent, value: string) => {
        const queryParams = new URLSearchParams(location.search);
        let pressedButton: any = e.target;

        while (!pressedButton.classList.contains('MuiButtonBase-root') && pressedButton.parentElement) {
            pressedButton = pressedButton.parentElement;
        }

        if (anchor.current === pressedButton) {
            anchor.current = null;
        } else {
            anchor.current = pressedButton;
        }

        if (!value) {
            queryParams.delete('lpanel');
        } else {
            queryParams.set('lpanel', value);
        }

        setSearchParams(queryParams);
    };

    useEffect(() => {
        if (!props.isLoading) {
            const queryParams = new URLSearchParams(location.search);
            let panel = queryParams.get('lpanel');
            let context = queryParams.get('context');

            if (panel?.length > 0 && anchor.current === null) {
                let activeButton = Array.from(buttonGroup.current?.children)?.find((child: any) => child.id === panel);
                anchor.current = activeButton;
            }

            if (context === 'new') {
                setProjectSettingsToggle(true);
                queryParams.delete('context');
                setSearchParams(queryParams.toString());
            }

            switch (panel) {
                case 'console':
                    setCurrentPanel(LeftSideBarPanels.CONSOLE);
                    break;
                case 'models':
                    setCurrentPanel(LeftSideBarPanels.MODELS);
                    break;
                case 'documents':
                    setCurrentPanel(LeftSideBarPanels.DOCUMENTS);
                    break;
                case 'search':
                    setCurrentPanel(LeftSideBarPanels.SEARCH);
                    break;
                default:
                    setCurrentPanel(LeftSideBarPanels.NONE);
                    break;
            }

            props.onSidebarChange(panel !== null, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, setSearchParams, props.isLoading]);

    const onSettingsDialogClosed = (dialogResult: boolean) => {
        //dialogResult: dialog was accwepted or canceled
        setProjectSettingsToggle(false);
    };

    const onLoadingComplete = () => {
        let entities = viewer.getEntities(false);
        setVisualEntities(entities);
    };
    useEffect(() => {
        let observedViewer;

        if (viewer) {
            observedViewer = viewer;
            observedViewer.loadingProgress.onComplete(onLoadingComplete);
        }

        return () => {
            if (observedViewer) {
                observedViewer.loadingProgress.RemoveOnComplete(onLoadingComplete);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewer]);

    const getDocuments = useCallback(() => {
        return <ToggleButton disabled={documentsDisabled} id="documents" value="documents" classes={{ root: styles.button, selected: styles.buttonSelected }}>
            <FolderIcon fontSize="large" />
            <Typography variant="caption" classes={{ root: styles.buttonText }}>
                {t('LeftNavBar.Documents')}
            </Typography>
        </ToggleButton>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentsDisabled]);

    return (
        <Box className={styles['sidebar-container']}>
            <ModelsPanel
                open={currentPanel === LeftSideBarPanels.MODELS}
                onClose={handlePanelClose}
                models={props.models}
                project={props.projectNode}
                onModelAdded={props.onModelAdded}
                onModelRemoved={props.onModelRemoved}
            />
            <HierarchyPanel
                open={currentPanel === LeftSideBarPanels.CONSOLE}
                onClose={handlePanelClose}
                projectNode={props.projectNode}
                onVisibilityToggle={onVisibilityToggle}
                onSelection={onSelection}
                onNodeHover={onNodeHover}
                selected={props.selection}
                onIsolation={props.onIsolation}
                isolatedHandle={props.isolatedHandle}
            />
            <DocumentsPanel
                open={currentPanel === LeftSideBarPanels.DOCUMENTS && !documentsDisabled}
                projectId={props.projectId}
                onClose={handlePanelClose}
                folders={props.folders}
                setFolders={props.setFolders}
                copiedFile={props.copiedFile}
                setCopiedFile={props.setCopiedFile}
                handleAddFile={props.handleAddFile}
            />
            <SearchPanel
                open={currentPanel === LeftSideBarPanels.SEARCH}
                project={props.projectNode}
                onClose={handlePanelClose}
                projectNode={props.projectNode}
                onVisibilityToggle={props.onVisibilityToggle}
                modelviewer={props.modelviewer}
                allVisualEntities={visualEntities}
            />
            <Box className={styles['button-container']}>
                <Indicator anchor={anchor.current} />
                <ToggleButtonGroup ref={buttonGroup} value={currentPanel} exclusive orientation="vertical" onChange={handleTab}>
                    <ToggleButton
                        disabled={props.isLoading}
                        id="console"
                        value="console"
                        classes={{ root: styles.button, selected: styles.buttonSelected }}>
                        <AccountTreeIcon fontSize="large" />
                        <Typography variant="caption" classes={{ root: styles.buttonText }}>
                            {t('LeftNavBar.Console')}
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        disabled={props.isLoading}
                        id="models"
                        value="models"
                        classes={{ root: styles.button, selected: styles.buttonSelected }}>
                        <HolidayVillageIcon fontSize="large" />
                        <Typography variant="caption" classes={{ root: styles.buttonText }}>
                            {t('LeftNavBar.Models')}
                        </Typography>
                    </ToggleButton>
                    {documentsDisabled ? <Tooltip title={t('LeftNavBar.NotEnabledForPolicy')}>
                        <Box className={styles['wrapper-container']}>{getDocuments()}</Box>
                    </Tooltip>
                        : getDocuments()
                    }
                    <ToggleButton
                        disabled={props.isLoading}
                        id="search"
                        value="search"
                        classes={{ root: styles.button, selected: styles.buttonSelected }}>
                        <SearchIcon fontSize="large" />
                        <Typography variant="caption" classes={{ root: styles.buttonText }}>
                            {t('LeftNavBar.Search')}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButton
                    disabled={props.isLoading}
                    className={styles.hide}
                    style={{ position: 'absolute', width: '100%', bottom: '0', left: '0', right: '0' }}
                    value="settings"
                    classes={{ root: styles.button, selected: styles.buttonSelected }}
                    selected={projectSettingsToggleOn}
                    onChange={() => {
                        setProjectSettingsToggle(!projectSettingsToggleOn);
                    }}>
                    <SettingsIcon fontSize="large" />
                    <Typography variant="caption" classes={{ root: styles.buttonText }}>
                        {t('LeftNavBar.Settings')}
                    </Typography>
                </ToggleButton>
            </Box>

            <SettingsDialog open={projectSettingsToggleOn} onClose={onSettingsDialogClosed} project={props.projectNode?.projectItem} />
        </Box>
    );
}
