import { Box, CircularProgress } from '@mui/material';
import ModelViewer from 'components/ModelViewer/ModelViewer';
import styles from 'components/Projects/ProjectPage.module.scss';
import LoginPopup from 'components/PublicViewer/LoginPopup/LoginPopup';
import NavBar from 'components/Shared/NavBar';
import { ProjectInformationContext } from 'context/ProjectInformationContext';
import useSession from 'hooks/useSession';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigationType, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import restAPI from 'services/rest-api';
import publicProjectPoliciesAtom, { IPublicProjectPolicy } from '../atoms/publicProjectPoliciesAtom';
import RequestAccessPage from './RequestAccessPage';

const asyncIfCondition = async (
    clauseFunction: () => Promise<boolean>,
    successFunction: () => Promise<IPublicProjectPolicy | null>,
    failFunction: () => Promise<IPublicProjectPolicy | null>
) => ((await clauseFunction()) ? successFunction() : failFunction());
const getPublicProjectPolicyStatements = (projectId: string): (() => Promise<IPublicProjectPolicy | null>) => {
    const getPermissionsForPublicProjectAndNavToPubViewer = async (): Promise<IPublicProjectPolicy | null> => {
        const publicProjectPolicyStatements = await restAPI.getPublicProjectPolicyStatements(projectId);
        return publicProjectPolicyStatements;
    };
    return () =>
        asyncIfCondition(
            () => restAPI.getIsProjectPublic(projectId),
            /*public project*/
            getPermissionsForPublicProjectAndNavToPubViewer,
            /*private project*/
            () => Promise.resolve({ projectId: projectId, isPublic: false })
        );
};

export default function ProjectPage() {
    const navType = useNavigationType();
    const navigate = useNavigate();
    const { component, id } = useParams<any>();
    const [hasAccess, setHasAccess] = useState(navType === 'PUSH');
    const [isLoading, setLoading] = useState(navType !== 'PUSH');
    const [, setPublicProjectPolicies] = useRecoilState(publicProjectPoliciesAtom);
    const loadingAsyncData = useRef(false);
    const [showGuestDialog, setShowGuestDialog] = useState(false);
    const { getSession, setSession, isGuest, startGuestSession } = useSession();
    const [currSession, setCurrSession] = useState(null);
    const [projectInformation, setProjectInformation] = useState(null);

    // Navigate to 404 if no project found
    if (!['project'].includes(component)) {
        navigate('/404', { replace: true });
    }

    useEffect(() => {
        setShowGuestDialog(isGuest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currSession]);

    const checkAccess = () => {
        return restAPI
            .getProjectAccess(id)
            .then((res) => {
                const { access } = res.data;
                setHasAccess(access);
                if (access) {
                    let ses = getSession();
                    setCurrSession(ses);
                    return restAPI.getProjectInfo(id);
                }
                return Promise.reject('no access');
            })
            .then((data) => {
                return getPublicProjectPolicyStatements(id)?.();
            })
            .then((projectsPolicyStatements) => {
                setPublicProjectPolicies(projectsPolicyStatements);
                return Promise.resolve({
                    code: 200,
                    message: 'Successfully fetched access.',
                    data: { project: id, policy: projectsPolicyStatements },
                });
            })
            .catch((rejection) => {
                console.warn('Could not get access to project: ' + rejection);
            });
    };

    useEffect(() => {
        if (loadingAsyncData.current === true) {
            return;
        }
        loadingAsyncData.current = true;
        let mounted = true;
        let ses = getSession();
        (!ses ? startGuestSession() : Promise.resolve(ses))
            .then((session) => {
                setCurrSession(session);
                return checkAccess();
            })
            .catch((err) => {
                console.error('Something went wrong while fetching project with id ' + id);
                if (window.location.hostname === 'localhost') console.error(err);
                if (!mounted) return;
                setHasAccess(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        let ses = getSession();
        (!ses ? startGuestSession() : Promise.resolve(ses))
            .then((ses) => restAPI.getProjectInfo(id))
            .then((result) => {
                const projectInfo = {
                    createdOn: null,
                    lastUpdatedOn: null,
                    name: result.data.name,
                    teamName: result.data.teamName,
                    projectId: result.data.projectID,
                    projectLink: null,
                };
                setProjectInformation(projectInfo);
            })
            .catch((err) => {
                console.error(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleLogin = (response) => {
        const user = response?.data?.user;
        if (user) {
            setSession(user);
            setCurrSession(user);
        }
        checkAccess()
            .catch((err) => {
                console.error('Something went wrong while fetching project with id ' + id);
                setHasAccess(false);
                if (window.location.hostname === 'localhost') console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box>
            {isLoading ? (
                <Box className={styles['content-layout']}>
                    <Box sx={{ gridArea: 'main' }} className={styles['main-middle']}>
                        <CircularProgress />
                    </Box>
                </Box>
            ) : hasAccess ? (
                <Box className={styles['content-layout']}>
                    <ProjectInformationContext.Provider
                        value={{ projectInformation: projectInformation, setProjectInformation: setProjectInformation }}>
                        <Box sx={{ gridArea: 'header' }}>
                            <NavBar />
                        </Box>
                        <Box sx={{ gridArea: 'main' }}>{component === 'project' && <ModelViewer />}</Box>
                    </ProjectInformationContext.Provider>
                </Box>
            ) : (
                <Box>
                    <Box sx={{ gridArea: 'main' }}>
                        <RequestAccessPage />
                    </Box>
                </Box>
            )}
            <LoginPopup open={showGuestDialog} setOpen={setShowGuestDialog} onLogin={handleLogin} />
        </Box>
    );
}
