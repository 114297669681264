import addedTeamRoleAtom from 'atoms/addedTeamRoleAtom'
import amITeamAdminAtom from 'atoms/amITeamAdmin'
import deletedTeamRoleAtom from 'atoms/deletedTeamRoleAtom'
import editTeamRoleAtom from 'atoms/editTeamRoleAtom'
import selectedTeamAtom from 'atoms/selectedTeamAtom'
import selectedTeamRoleAtom from 'atoms/selectedTeamRoleAtom'
import updatedTeamRoleAtom from 'atoms/updatedTeamRoleAtom'
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'
import useArray from 'hooks/useArray'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ITeamPermission, ITeamRole, TeamPermissionId } from 'types/teamRoles'
import restAPI from '../../services/rest-api'
import TeamRole from './TeamRole'
import TeamRoleList from './TeamRoleList'

const getPermFromPolicy = (roleDB, resource): ITeamPermission | boolean => {
    const policyStatementDB = roleDB?.Statements?.find(st => st?.Resource === resource)
    if (policyStatementDB === undefined) {
        return { notDefined: true }
    }
    if (policyStatementDB.Resource === 'team') {
        return policyStatementDB?.Name === 'admin'
    }
    switch (policyStatementDB?.Name) {
        case 'edit': return { canEdit: true }
        case 'read': return { canRead: true }
        case 'none': return { notDefined: true }
        default: return { notDefined: true }
    }
}

const formatTeamRoles = (teamID, rolesDB) => {
    return rolesDB?.map(roleDB => {
        return {
            roleId: roleDB._id,
            teamID: teamID,
            roleName: roleDB.Name,
            teamAdmin: getPermFromPolicy(roleDB, 'team'),
            documents: getPermFromPolicy(roleDB, 'document'),
            issues: getPermFromPolicy(roleDB, 'issue'),
            truckLoading: getPermFromPolicy(roleDB, 'hsbstacking')
        }
    });
}

const getTeamRoles = (teamID): Promise<ITeamRole[]> => {
    return new Promise((resolve, reject) => {
        restAPI.getTeamPolicyRoles(teamID).then(roles => {
            const result = formatTeamRoles(teamID, roles)
            return resolve(result);
        })
    });
}

const TeamRolesController: React.FC<React.PropsWithChildren<{}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = () => {
    const { t } = useTranslation('common');
    let amITeamAdmin = useRecoilValue(amITeamAdminAtom);
    const [selectedTeam,] = useRecoilState(selectedTeamAtom);
    const { items, setItems, add, update, remove } = useArray([]);
    const [requireFetchCounter, setRequireFetchCounter] = useState(0);
    const [, setEditMode] = useRecoilState(editTeamRoleAtom);
    const [, setSelectedTeamRole] = useRecoilState(selectedTeamRoleAtom);
    const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' });
    const [updatedTeamRole, setUpdatedTeamRole] = useRecoilState(updatedTeamRoleAtom);
    const [, setAddedTeamRole] = useRecoilState(addedTeamRoleAtom);
    const [, setDeletedTeamRole] = useRecoilState(deletedTeamRoleAtom);
    const queryClient = useQueryClient();

    const setLastAdmin = (roles: ITeamRole[]) => {
        const numberOfAdmins = roles?.reduce((acc, role) => role.teamAdmin ? acc + 1 : acc, 0)
        const lastAdmin = numberOfAdmins <= 1
        roles?.forEach(role => role.lastAdmin = lastAdmin)
        roles = roles?.sort((ra, rb) => ra?.roleName?.localeCompare(rb?.roleName, 'en', { sensitivity: 'base' })) ?? []
        return roles;
    }

    useEffect(() => {
        if (!selectedTeam?.teamID || selectedTeam?.selectedIndex === undefined) {
            return;
        }
        getTeamRoles(selectedTeam.teamID).then(teamRoles => {
            setItems(setLastAdmin(teamRoles))
        })
    }, [setItems, selectedTeam, requireFetchCounter, updatedTeamRole])

    const addRole = async () => {
        const newTeamRoleNumber = items.filter(role => role.roleName.indexOf('New Team Role') >= 0).length;
        const newTeamRoleName = newTeamRoleNumber > 0 ? `New Team Role ${newTeamRoleNumber}` : 'New Team Role';
        try {
            if (!selectedTeam?.teamID) {
                return;
            }

            const newRoleDB = await restAPI.insertTeamPolicyRole(selectedTeam.teamID, newTeamRoleName, [
                { name: 'none', resource: 'team' },
                { name: 'edit', resource: 'document' },
                { name: 'edit', resource: 'issue' },
                { name: 'edit', resource: 'hsbstacking' }
            ])
            const newRole = {
                teamID: selectedTeam.teamID,
                roleId: newRoleDB?._id,
                roleName: newRoleDB?.Name,
                teamAdmin: getPermFromPolicy(newRoleDB, 'team'),
                documents: getPermFromPolicy(newRoleDB, 'document'),
                issues: getPermFromPolicy(newRoleDB, 'issue'),
                truckLoading: getPermFromPolicy(newRoleDB, 'hsbstacking')
            }
            add(newRole)
            setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.TeamRoleAdded') })
            setAddedTeamRole({ role: newRole.roleName, roleId: newRole.roleId });
            setSelectedTeamRole({ teamID: selectedTeam.teamID, role: newRoleDB?.Name, roleId: newRole.roleId, index: items.length })
            setEditMode(true)
        } catch (error) {
            if (error === `Team role names must be unique. There exists already a team role with name: ${newTeamRoleName}`) {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.DupRoleName', { roleName: newTeamRoleName }) });
            }
            console.error(error)
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.AddingTeamRole') })
        }
    }

    if (!amITeamAdmin) {
        return null;
    }

    const getNextRolePermission = (teamPermission: ITeamPermission): ITeamPermission => {
        if (teamPermission.canEdit) {
            return { notDefined: true }
        } else if (teamPermission.canRead) {
            return { canEdit: true }
        }
        return { canRead: true }
    }

    const getPolicyNameAsString = (teamPermission: ITeamPermission): string => {
        if (teamPermission?.canEdit === true) {
            return 'edit'
        }
        else if (teamPermission?.canRead === true) {
            return 'read'
        }
        return 'none'
    }

    const getPropName = (permissionId: TeamPermissionId) => {
        switch (permissionId) {
            case "team": return "teamAdmin"
            case "document": return "documents"
            case "issue": return "issues"
            case "hsbstacking": return "truckLoading"
            default: return ""
        }
    }

    const toggleRolePermission = async (teamID: string, roleId: string, roleName: string, initialTeamPermission: ITeamPermission, teamPermissionId: TeamPermissionId) => {
        try {
            const newPolicyStatementResource = teamPermissionId
            const newPolicyStatementName = getNextRolePermission(initialTeamPermission)
            const policyName = getPolicyNameAsString(newPolicyStatementName)
            const result = await restAPI.updateStatementNameByResource(teamID, roleId, newPolicyStatementResource, policyName)
            if (result?.acknowledged === true && result?.matchedCount === 1 && result?.modifiedCount === 1) {
                update('roleId', roleId, getPropName(newPolicyStatementResource), newPolicyStatementName);
                const role = items.find(role => role.roleId === roleId);
                queryClient.invalidateQueries(['teamUserPermissions', teamID]);
                setUpdatedTeamRole({ ...role, [getPropName(newPolicyStatementResource)]: newPolicyStatementName });
                setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.TeamRolePermissionUpdated', { permissionName: role, newValue: getPolicyNameAsString(newPolicyStatementName) }) })
            } else {
                console.warn(result)
                setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UpdateTeamRolePermission') })
            }
        } catch (error) {
            if (error === 'failed at least one team admin validation.') {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.AtLeastOneTeamAdmin') })
            }
            console.error(error)
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UnhandledTeamRolePermissionUpdated') })
        }
    }

    const toggleTeamAdmin = async (teamID: string, roleId: string, isTeamAdmin: boolean, e: React.ChangeEvent<HTMLInputElement>, roleName: string) => {
        try {
            const result = await restAPI.updateStatementNameByResource(teamID, roleId, 'team', isTeamAdmin ? 'admin' : 'none')
            if (result?.acknowledged === true && result?.matchedCount === 1 && result?.modifiedCount === 1) {
                setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.TeamRolePermissionUpdated', { permissionName: roleName, newValue: isTeamAdmin }) })
            } else {
                console.error('error setting team role admin. result' + JSON.stringify(result))
                setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UpdateTeamRolePermission') })
            }
            setRequireFetchCounter(requireFetchCounter + 1)
        } catch (error) {
            if (error === 'failed at least one team admin validation.') {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.AtLeastOneTeamAdmin') })
            }
            console.error(error)
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UnhandledTeamRolePermissionUpdated') })
        }
    }

    const deleteRolePermission = async (teamID, roleId, roleName) => {
        try {
            const result = await restAPI.deleteTeamPolicy(teamID, roleId);
            if (result?.deletedCount === 1) {
                setDeletedTeamRole({ roleId: roleId, role: roleName });
                remove('roleId', roleId)
                setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.TeamRoleRemoved', { roleName: roleName }) })
            } else {
                console.warn(result);
                setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UnexpectedNoRolesRemoved') })
            }
        } catch (error) {
            if (error === 'failed at least one team admin validation.') {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.AtLeastOneTeamAdmin') })
            } else if (error === 'Not allowed. Team has members assigned to the role to be deleted.') {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UsersWithThatRole', { roleName: roleName }) })
            }
            console.error(error)
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.RemoveTeamRole') })
        }
    }

    const updateRoleName = async (teamID, roleId, oldRoleName, newRoleName) => {
        try {
            const result = await restAPI.updateTeamPolicyName(teamID, roleId, newRoleName)
            if (result?.acknowledged === true && result?.matchedCount === 1 && result?.modifiedCount === 1) {
                update('roleId', roleId, 'roleName', newRoleName);
                const role = items.find(role => role.roleId === roleId);
                setUpdatedTeamRole({ ...role, role: newRoleName, oldRoleName });
                setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.TeamRoleNameUpdated', { newValue: newRoleName }) });
            } else if (result?.acknowledged === true && result?.matchedCount === 1 && result?.modifiedCount === 0) {
                //the name didnt change so no records was modified;nothing to do ;no error.
            } else {
                console.warn(result)
                setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UpdateNameTeamRole') })
            }
        } catch (error) {
            if (error === `Team role names must be unique. There exists already a team role with name: ${newRoleName}`) {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.DupRoleName', { roleName: newRoleName }) });
            }
            console.error(error)
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UnexpectedUpdateNameTeamRole') })
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <TeamRoleList addRole={addRole}>
                {items.map((teamRoleData, index) => <TeamRole key={teamRoleData.roleId}
                    teamID={teamRoleData.teamID}
                    roleId={teamRoleData.roleId}
                    roleName={teamRoleData.roleName}
                    teamAdmin={teamRoleData.teamAdmin}
                    lastAdmin={teamRoleData.lastAdmin}
                    documents={teamRoleData.documents}
                    issues={teamRoleData.issues}
                    truckLoading={teamRoleData.truckLoading}
                    toggleRolePermission={toggleRolePermission}
                    toggleTeamAdmin={toggleTeamAdmin}
                    deleteRolePermission={deleteRolePermission}
                    updateRoleName={updateRoleName}
                    index={index}
                />)}
            </TeamRoleList>
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
        </div>
    )
}

export default TeamRolesController;