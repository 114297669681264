import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import HelpIcon from "@mui/icons-material/Help";
import LinkIcon from "@mui/icons-material/Link";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FormControl, IconButton, InputBase, List, ListItem, MenuItem, Paper, Select, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import publicProjectPoliciesAtom, { IProjectUserPolicy } from "atoms/publicProjectPoliciesAtom";
import DeleteDialog from "components/Shared/DeleteDIalog/DeleteDialog";
import DialogBox from "components/Shared/DialogBox";
import EditableInput from "components/Shared/EditableInput/EditableInput";
import { FormInputSelect } from "components/Shared/FormComponents/FormInputSelect/FormInputSelect";
import { FormInputSelectMenuItem } from "components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem";
import ListSearch from "components/Shared/List/Search/ListSearch";
import { ProjectInformationContext } from "context/ProjectInformationContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import restAPI from "services/rest-api";
import taskManager from "services/taskManager";
import SnackBarMessage, { ISnackBarData } from "../../Shared/snackBar/SnackBarMessage";
import ProjectAddMembersDialog from "../ProjectAddMembersDialog";
import styles from "./SettingsDialog.module.scss";

const Item = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
}));

function a11yProps(index) {
    return {
        id: `full-width-settings-tab-${index}`,
        "aria-controls": `full-width-settings-tabpanel-${index}`,
    };
}

export const ProjectPrivacySelect: React.FC<{ privacy: boolean, isTeamAdmin: boolean, projectId: string, setSnackBar: any }> = ({ privacy, isTeamAdmin, projectId, setSnackBar }) => {
    const { t } = useTranslation("common");
    const [isProjectPublic, setIsProjectPublic] = React.useState(privacy);
    const [isRequestingPrivacyChange, setIsRequestingPrivacyChange] = React.useState<boolean>(false);

    const handleChange = () => {
        if (isTeamAdmin && !isRequestingPrivacyChange) {
            const oldPrivacyValue = isProjectPublic;
            setIsRequestingPrivacyChange(true);
            setIsProjectPublic(!isProjectPublic);
            restAPI.togglePublicProjectSetting(projectId).then(result => {
                setSnackBar({ open: true, severity: 'success', message: t('SettingsDialog.SuccessPublicSetting', { publicSetting: result.data.isPublic ? t('SettingsDialog.Public') : t('SettingsDialog.Private') }) });
            }).catch(error => {
                setIsProjectPublic(oldPrivacyValue);
                setSnackBar({ open: true, severity: 'error', message: error?.message ?? t('SettingsDialog.ErrorPublicSetting', { publicSetting: isProjectPublic ? t('SettingsDialog.Private') : t('SettingsDialog.Public') }) });
            }).finally(() => setIsRequestingPrivacyChange(false));
        }
    };

    return (
        <FormControl variant="standard" className={styles["copy-link-private-settings"]}>
            <Select value={isProjectPublic?.toString()} onChange={handleChange} disableUnderline sx={{ color: "rgba(0, 0, 0, 0.39)" }} disabled={!isTeamAdmin || isRequestingPrivacyChange}>
                <MenuItem value={"true"}>{t("SettingsDialog.Public")}</MenuItem>
                <MenuItem value={"false"}>{t("SettingsDialog.Private")}</MenuItem>
            </Select>
        </FormControl>
    );
};

export const ShareProjectInput: React.FC<{ text: string, isTeamAdmin: boolean, projectId: string, isPublic: boolean, setSnackBar: any }> = ({ text, isTeamAdmin, projectId, isPublic, setSnackBar }) => {
    return (
        <Box component="form" className={styles["copy-link-container"]}>
            <LinkIcon sx={{ ml: 1, mr: 1 }} />
            <InputBase className={styles["copy-link-input"]} value={text} readOnly={true} onClick={(e: any) => e.target.select()} />
            {
                typeof isPublic !== 'undefined' &&
                <ProjectPrivacySelect privacy={isPublic} isTeamAdmin={isTeamAdmin} projectId={projectId} setSnackBar={setSnackBar} />
            }
        </Box>
    );
};

const copyToClipboard = async (text) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        return await navigator.clipboard.writeText(text);
    }
    return Promise.reject("The Clipboard API is not available.");
};

function SettingsDialog(props) {
    const { projectInformation, setProjectInformation } = useContext(ProjectInformationContext);
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = useState<string>(null);
    const [projectMembers, setProjectMembers] = useState<any[]>([]);
    const [projectRoleNames, setProjectRoleNames] = useState<IProjectUserPolicy[]>([]);
    const { t } = useTranslation("common");
    const [tabValue, setTabValue] = React.useState("General");
    const [snackBar, setSnackBar] = React.useState<ISnackBarData>({ open: false, severity: "success", message: "", });
    const [publicPermissions, setPublicPermissions] = useState<Array<{ name: "read" | "none"; resource: string }>>
        ([
            {
                name: "none",
                resource: "document",
            },
            {
                name: "none",
                resource: "issue",
            },
        ]);
    const [isTeamAdmin, setIsTeamAdmin] = useState(false);
    const [openChild, setOpenChild] = React.useState(false);
    const [usesrData, setAddedUsersData] = React.useState(null);
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [, setProjectPolicy] = useRecoilState(publicProjectPoliciesAtom);
    const { control } = useForm<any>();
    const [roleOptions, setRoleOptions] = useState([]);
    const [openDeleteProject, setOpenDeleteProject] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.project?.projectID) {
            return;
        }
        restAPI.getPublicProjectPolicyStatements(props.project.projectID).then(result => {
            setProjectPolicy(result)
        }).catch(error => {
            console.error('Error getting isPublic project private from backend. Detail: ' + JSON.stringify(error ?? 'no info'))
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.projectId, publicPermissions]);

    const handleCloseConfirmDialog = () => {
        setMemberToDelete(null);
    };

    const prepareToDelete = (member) => {
        setMemberToDelete(member);
    };

    const handleClose = () => {
        setOpen(false);
        props.onClose(null);
        setSearchTerm(null);
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        if (props.project?.projectID) {
            restAPI.getProjectsUserPolicies(props.project.projectID).then((roles) => {
                setRoleOptions(getRoleOptions(roles));
                setProjectRoleNames(roles?.map((roleObj) => roleObj));
                restAPI.getProjectUsersWithPolicyRoles(props.project.projectID).then((data) => {
                    const projMembers =
                        data?.map((member: any) => {
                            member.selectedPolicyRole = member?.policy?.policyId;
                            return member;
                        }) ?? [];
                    setProjectMembers(projMembers);
                });
            });
            restAPI.getPublicProjectPolicyStatements(props.project.projectID).then((d) => {
                if (d?.policies) {
                    setPublicPermissions(
                        publicPermissions.map((permission) => {
                            return (
                                d.policies.find((policy) => policy.resource === permission.resource) || permission
                            );
                        })
                    );
                }
            });
        }

        if (props.project?.teamID) {
            restAPI.isTeamAdmin(props.project.teamID).then((result) => {
                const isTeamAdmin = result?.[0] ?? false;
                setIsTeamAdmin(isTeamAdmin);
            });
        }

        //Set project settings
        if (props.project) {
            setProjectInformation({
                ...projectInformation, ...{
                    createdOn: props.project.nodeModel.created.on,
                    lastUpdatedOn: props.project.nodeModel.edited.on,
                    projectLink: window.location.href
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.project, usesrData]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleClickCopy = async () => {
        try {
            await copyToClipboard(projectInformation.projectLink);
            setSnackBar({ open: true, severity: "success", message: t("SettingsDialog.CopySuccess"), });
        } catch (error) {
            console.error(error);
            setSnackBar({ open: true, severity: "error", message: t("SettingsDialog.CopyError"), });
        }
    };

    const handleSearch = (value: string) => {
        setSearchTerm(value);
    };

    const handleDeleteMember = () => {
        restAPI.deleteUserFromProject(memberToDelete.email, props.project?.projectID).then(result => {
            const projMembers = projectMembers.filter((user) => user._id !== memberToDelete._id);
            setProjectMembers(projMembers);
        }).catch(error => {
            setSnackBar({ open: true, severity: "error", message: "error deleting user " + error });
        }).finally(() => {
            setMemberToDelete(null);
        })
    };

    const handleAddMember = () => {
        setOpenChild(true);
    };

    const handleChangeRole = (item: any, user: any) => {
        const policyId = item.target.value;
        restAPI.updateProjectUserPolicyRole(user.email, props.project?.projectID, policyId).then((result) => {
            const projMembers = projectMembers.map((member) => {
                if (member._id === user._id) {
                    member.selectedPolicyRole = policyId;
                }
                return member;
            });
            setProjectMembers(projMembers);
        });
    };

    const handleCloseChild = () => {
        setOpenChild(false);
    };

    const addProjectUsers = async (data: any) => {
        const workItems: Array<Awaited<Promise<any>>> = data.reduce(
            (acc, member) => {
                acc.unshift(() =>
                    restAPI.addProjectUserWithPolicyRole(member.username, props.project?.projectID, member.roleId));
                return acc;
            }, []
        );
        let leftOver = await taskManager.forkWithRetry(workItems);
        if (leftOver.length > 0) {
            return setSnackBar({ open: true, severity: "error", message: t("TeamMemberListController.InviteFailed") + " errors: " + JSON.stringify(Array.from(taskManager.errors)) });
        }
        setAddedUsersData(data);
        setOpenChild(false);
        setSnackBar({ open: true, severity: "success", message: t("TeamMemberListController.UsersInvited"), });
    };

    const onInvite = async (data: any) => {
        try {
            await addProjectUsers(data);
        } catch (error) {
            setSnackBar({ open: true, severity: "error", message: t("TeamMemberListController.InviteFailed"), });
            console.error(error);
        }
    };

    useEffect(() => {
        if (props.project?.teamID) {
            restAPI.isTeamAdmin(props.project.teamID).then((result) => {
                const isTeamAdmin = result?.[0] ?? false;
                setIsTeamAdmin(isTeamAdmin);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.project]);

    const handleTogglePermission = (permission: "document" | "issue") => {
        const state = publicPermissions.find(p => p.resource === permission),
            newValue = state.name === "read" ? "none" : "read",
            newPermissions: Array<{ name: "read" | "none", resource: string }> = publicPermissions.map(p => {
                if (p.resource === permission) {
                    return { ...p, name: newValue }
                }
                return p;
            });
        restAPI.updatePublicProjectPolicies(props.project?.projectID, newPermissions).then(() => {
            setPublicPermissions(newPermissions);
            setSnackBar({ open: true, severity: 'success', message: t("SettingsDialog.PermissionChangeSuccess", { permission: permission, value: newValue }) });
        });
    }

    const getIcon = (permission: string) => {
        const state = publicPermissions.find(p => p.resource === permission);
        switch (state?.name) {
            case "read":
                return <VisibilityIcon />;
            case "none":
                return <CloseIcon sx={{ color: "#B7B7B7" }} />;
            default:
                return <CloseIcon sx={{ color: "#B7B7B7" }} />;
        }
    }

    const handleTitleChange = (value: string) => {
        if (value.length === 0) {
            return;
        }

        restAPI.editProjectName(props.project.projectID, value).then(data => {
            setProjectInformation({
                createdOn: projectInformation.createdOn,
                lastUpdatedOn: projectInformation.lastUpdatedOn,
                name: value,
                teamName: projectInformation.teamName,
                projectId: projectInformation.projectId,
                projectLink: projectInformation.projectLink
            });
            setSnackBar({ open: true, severity: "success", message: t("SettingsDialog.EditTitleSuccess", { name: value }) });
        }).catch(err => {
            setSnackBar({ open: true, severity: "error", message: t("SettingsDialog.EditTitleFailure") });
        })
    }

    const getRoleOptions = (roles: IProjectUserPolicy[]) => {
        const policies = roles.map(p => {
            return <FormInputSelectMenuItem key={p.id} value={p.id}>{p.name}</FormInputSelectMenuItem>
        })
        return policies;
    }

    const handleDeleteProject = () => {
        restAPI.deleteProject(projectInformation.projectId).then(result => {
            navigate(`/projects`);
        }).catch(error => {
            setSnackBar({ open: true, severity: "error", message: t("ProjectItem.ProjectDeleteFailure") });
        }).finally(() => {
            setOpenDeleteProject(false);
        })
    }

    return (
        <Box>
            <DialogBox aria-labelledby="settings-dialog" open={open} onClose={handleClose}>
                <DialogTitle>
                    {t("SettingsDialog.Title")}
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <TabContext value={tabValue}>
                            <Box>
                                <TabList variant="fullWidth" onChange={handleTabChange}>
                                    <Tab label={t("SettingsDialog.General")} value="General" {...a11yProps(0)} />
                                    <Tab label={t("SettingsDialog.Members")} value="Members" {...a11yProps(3)} />
                                    <Tab label={t("SettingsDialog.Manage")} value="Manage" {...a11yProps(2)} />
                                </TabList>
                            </Box>
                            <TabPanel value="General" sx={{ padding: "0", margin: "18px 0 0 0", height: 352 }}>
                                <Box className={styles['project-info-container']}>
                                    <Box>
                                        <Typography>{t("SettingsDialog.Name")}</Typography>
                                        <EditableInput inputProps={{ className: styles['project-info-title'] }} text={projectInformation?.name} type={'text'} onSave={handleTitleChange} />
                                    </Box>
                                    <Box>
                                        <Typography>{t("SettingsDialog.Id")}</Typography>
                                        <Typography>{projectInformation?.projectId}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography>{t("SettingsDialog.CreatedOn")}</Typography>
                                        <Typography>{projectInformation?.createdOn && new Date(projectInformation?.createdOn).toLocaleString()}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography>{t("SettingsDialog.LastUpdatedOn")}</Typography>
                                        <Typography>{projectInformation?.lastUpdatedOn && new Date(projectInformation?.lastUpdatedOn).toLocaleString()}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography>{t("SettingsDialog.Team")}</Typography>
                                        <Typography>{projectInformation?.teamName}</Typography>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel value="Members" sx={{ padding: "0", margin: "18px 0" }}>
                                <div style={{ height: 352 }}>
                                    <Grid container spacing={0}>
                                        <Paper square={true} style={{ gridArea: "header", borderBottom: "1px solid #eeeeee", width: "100%", }} elevation={0}>
                                            <Box sx={{ border: "0px" }}>
                                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="h5" className={styles.panelTitle}>
                                                        {t("SettingsDialog.ManageAccess")}
                                                    </Typography>
                                                    <Box className={styles["actions-container"]}>
                                                        <ListSearch onFilter={handleSearch} />
                                                        <IconButton onClick={() => handleAddMember()}>
                                                            <PersonAddIcon sx={{ fill: "#f6a81c" }} />
                                                        </IconButton>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Paper>
                                        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ gridArea: "content", maxHeight: 347, width: "100%", }}>
                                            <List>
                                                {projectMembers.filter((m) => !searchTerm || m.name?.toLowerCase().includes(searchTerm) || m.email?.toLowerCase().includes(searchTerm))
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((m) => {
                                                        return (
                                                            <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }} key={m._id} className={styles['project-member-container']}>
                                                                <Box className={styles['project-member-action']}>
                                                                    {m.avatar?.URI ?
                                                                        <img src={m.avatar.URI} alt={`Avatar for ${m.name}`} /> :
                                                                        <Box className={styles['member-icon']} sx={{ backgroundColor: m.avatar?.backgroundColor || "#f6a81c" }}>
                                                                            <span>{m.name.substring(0, 1)}</span>
                                                                        </Box>
                                                                    }
                                                                    <IconButton onClick={() => prepareToDelete(m)} className={styles['project-member-action-delete']}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Box>
                                                                <Typography classes={{ root: styles["project-member-name"] }}>
                                                                    {m.name}
                                                                </Typography>
                                                                <Typography classes={{ root: styles["project-member-description"] }}>
                                                                    {m.email}
                                                                </Typography>
                                                                <FormInputSelect search control={control} onChange={(e) => handleChangeRole(e, m)} className={styles['project-member-roles']} name={`role-${m._id}`} placeholder={t("SettingsDialog.Policy.Select")} defaultValue={m.selectedPolicyRole}>
                                                                    {roleOptions}
                                                                </FormInputSelect>
                                                            </ListItem>
                                                        );
                                                    })}
                                            </List>
                                        </PerfectScrollbar>
                                    </Grid>
                                </div>
                            </TabPanel>
                            <TabPanel value="Manage" sx={{ padding: "0", marginTop: "18px" }}>
                                <Box className={styles['manage-tab-container']} sx={{ minHeight: 352 }} display="flex" flexDirection={"column"} gap={"50px"}>
                                    <Box>
                                        <Box className={styles["project-settings-heading-container"]}>
                                            <Typography className={styles["project-settings-heading"]}>
                                                {t("SettingsDialog.ProjectLink")}
                                            </Typography>
                                        </Box>
                                        <Grid item>
                                            <Item sx={{ padding: 0 }}>
                                                <Stack direction="row" spacing={0} gap={"10px"}>
                                                    <Item sx={{ flexGrow: 1, padding: 0 }}>
                                                        <ShareProjectInput text={window.location.href} isTeamAdmin={isTeamAdmin} projectId={props.project?.projectID} isPublic={props.project?.isPublic} setSnackBar={setSnackBar} />
                                                    </Item>
                                                    <Item sx={{ flexGrow: 0, padding: 0 }}>
                                                        <Button onClick={handleClickCopy} variant="contained">
                                                            {t("SettingsDialog.Copy")}
                                                        </Button>
                                                    </Item>
                                                </Stack>
                                            </Item>
                                        </Grid>
                                    </Box>
                                    {isTeamAdmin &&
                                        <Box>
                                            <Grid item xs={12}>
                                                <Item sx={{ padding: 0 }}>
                                                    <Stack spacing={0}>
                                                        <Box className={styles['public-project-settings-header']}>
                                                            <FolderIcon sx={{ fill: "#666" }} />
                                                            <WarningIcon sx={{ fill: "#666" }} />
                                                        </Box>
                                                        <Box className={styles['public-project-settings-values']} display={"flex"} justifyContent={"space-between"}>
                                                            <Box display={'flex'} alignItems={'center'} className={styles['project-settings-heading-container']}>
                                                                <Typography className={styles['project-settings-heading']}>{t("SettingsDialog.ManagePublicAccess")}</Typography>
                                                                <Tooltip title={t("SettingsDialog.ManagePublicAccessHelp")}>
                                                                    <HelpIcon />
                                                                </Tooltip>
                                                            </Box>
                                                            <Box display={"flex"}>
                                                                <Box onClick={() => handleTogglePermission("document")}>
                                                                    {getIcon("document")}
                                                                </Box>
                                                                <Box onClick={() => handleTogglePermission("issue")}>
                                                                    {getIcon("issue")}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                </Item>
                                            </Grid>
                                        </Box>
                                    }
                                    {
                                        isTeamAdmin &&
                                        <Box display={'flex'} alignItems={'center'} gap={'40px'}>
                                            <Box>
                                                <Typography className={styles['delete-project-title']}>{t("SettingsDialog.DeleteProject")}</Typography>
                                                <Typography className={styles['delete-project-help']}>{t('SettingsDialog.DeleteProjectHelp')}</Typography>
                                            </Box>
                                            <Button variant="contained" className={`${styles['delete-project-button']} ${styles['delete-button']}`} onClick={() => setOpenDeleteProject(true)}>{t("SettingsDialog.DeleteProject")}</Button>
                                        </Box>
                                    }
                                </Box>
                            </TabPanel>
                        </TabContext>
                        <DeleteDialog
                            open={!!memberToDelete}
                            onSubmit={handleDeleteMember}
                            onClose={handleCloseConfirmDialog}
                            title={`Remove ${memberToDelete?.name || memberToDelete?.email} from project`}
                            description={`Are you sure you want to remove ${memberToDelete?.name || memberToDelete?.email} from this project? This can not be reversed.`}
                            buttonText={"Remove"} />
                        <DeleteDialog
                            open={openDeleteProject}
                            onSubmit={handleDeleteProject}
                            onClose={() => setOpenDeleteProject(false)}
                            title={`Delete ${projectInformation?.name}`}
                            description={`Are you sure you want to delete ${projectInformation?.name}? This can not be reversed.`}
                            buttonText={"Delete this project"}
                            verification={projectInformation?.name}
                            caseSensitive />
                    </Box>
                </DialogContent>
            </DialogBox>
            <ProjectAddMembersDialog projectId={props.project?.projectID} onClose={handleCloseChild} roles={projectRoleNames} isOpen={openChild} onInvite={onInvite} />
            <SnackBarMessage severity={snackBar.severity} message={snackBar.message} open={snackBar.open} onSetOpen={setSnackBar} />
        </Box>
    );
}

SettingsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    project: PropTypes.any
};

export default SettingsDialog;
