import { MenuItem, MenuItemProps } from '@mui/material';
import styles from "./ContextualMenuItem.module.scss";

export default function ContextualMenuItem(props: ContextualMenuItemProps) {
    let { variant, ...menuItemprops } = props;

    return (
        <MenuItem classes={{ root: styles['contextual-menu-item-container'] }} className={styles[`variant-${variant}`]} {...menuItemprops}>
            {menuItemprops.children}
        </MenuItem>
    )
}

export interface ContextualMenuItemProps extends MenuItemProps {
    variant?: "red";
}