enum ViewerLayers {
    MODELS = 0,
    WIREFRAME = 1,
    INFO = 2,
    LABELS = 3,
    TOOLS = 4,
    ISSUES = 5,
    SIMPLEMODEL = 6
}

enum ViewerLayersBit {
    NONE = 0,
    MODELS = 1 << ViewerLayers.MODELS,
    WIREFRAME = 1 << ViewerLayers.WIREFRAME,
    INFO = 1 << ViewerLayers.INFO,
    LABELS = 1 << ViewerLayers.LABELS,
    TOOLS = 1 << ViewerLayers.TOOLS,
    ISSUES = 1 << ViewerLayers.ISSUES,
    SIMPLEMODEL = 1 << ViewerLayers.SIMPLEMODEL,
    ALL = ~(NONE)
}

export { ViewerLayers, ViewerLayersBit };
