import { faBarsStaggered, faCubesStacked, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import importSetCreatedAtom from 'atoms/importSetCreatedAtom';
import publicProjectPoliciesAtom, { IPublicProjectPolicy } from 'atoms/publicProjectPoliciesAtom';
import { Indicator } from 'components/Shared/ButtonGroupIndicator/ButtonGroupIndicator';
import usePermissions from 'hooks/usePermissions';
import useSession from 'hooks/useSession';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ImportSetsPanel from '../ImportSets/ImportSetsPanel';
import IssuesPanel from '../Issues/IssuesPanel';
import PropertiesPanel from '../Properties/PropertiesPanel';
import { RightSideToolBarProps } from '../ToolBarProps';
import styles from './ModelViewerToolbars.module.scss';

enum RightSideBarPanels {
    NONE = 'none',
    PROPERTIES = 'properties',
    ISSUES = 'issues',
    SCENES = 'scenes',
    IMPORT_SETS = 'importSets'
}

export default function RightSideBar(props: RightSideToolBarProps) {
    const { projectId } = props;
    const [, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { t } = useTranslation('common');
    const anchor = useRef(null);
    const buttonGroup = useRef(null);
    const [currentPanel, setCurrentPanel] = useState<RightSideBarPanels>(RightSideBarPanels.NONE);
    const [issuesDisabled, setIssuesDisabled] = useState(props.isLoading);
    const { isGuest } = useSession();
    const importSetCreated = useRecoilValue(importSetCreatedAtom);
    const { hasAccess } = usePermissions(['issue.read', 'issue.edit'], projectId);
    const projectsPolicyStatements: IPublicProjectPolicy = useRecoilValue(publicProjectPoliciesAtom);

    useEffect(() => {
        if (!projectsPolicyStatements || props.isLoading) {
            return;
        }

        if (!projectsPolicyStatements.isPublic) {
            //private project check team user role permissions 
            setIssuesDisabled(!hasAccess);
            return;
        }

        //public project use project policy role permissions
        const isGuestUser = isGuest();
        const canReadPublicIssuePolicies = projectsPolicyStatements?.policies?.find(
            (policy) => policy.resource === 'issue' && policy.name === 'read'
        );
        const canReadPublicIssues = canReadPublicIssuePolicies === undefined && isGuestUser; //only applies to public users.
        setIssuesDisabled(canReadPublicIssues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsPolicyStatements, hasAccess]);

    const handlePanelClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('rpanel');
    };

    useEffect(() => {
        if (!importSetCreated) {
            return;
        }
        const importSetButton = Array.from(buttonGroup.current?.children)?.find((child: any) => child.id === 'importSets');
        anchor.current = importSetButton;
        setCurrentPanel(RightSideBarPanels.IMPORT_SETS);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('rpanel', 'importSets');
        setSearchParams(queryParams.toString());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importSetCreated]);

    const handleTab = (e: SyntheticEvent, value: string) => {
        const queryParams = new URLSearchParams(location.search);
        let pressedButton: any = e.target;

        while (!pressedButton.classList.contains('MuiButtonBase-root') && pressedButton.parentElement) {
            pressedButton = pressedButton.parentElement;
        }

        if (anchor.current === pressedButton) {
            anchor.current = null;
        } else {
            anchor.current = pressedButton;
        }

        if (!value) {
            queryParams.delete('rpanel');
        } else {
            queryParams.set('rpanel', value);
        }

        setSearchParams(queryParams.toString());
    };

    useEffect(() => {
        if (!props.isLoading) {
            const queryParams = new URLSearchParams(location.search);
            let panel = queryParams.get('rpanel');

            if (panel?.length > 0 && anchor.current === null) {
                let activeButton = Array.from(buttonGroup.current?.children)?.find((child: any) => child.id === panel);
                anchor.current = activeButton;
            }

            switch (panel) {
                case 'properties':
                    setCurrentPanel(RightSideBarPanels.PROPERTIES);
                    break;
                case 'issues':
                    setCurrentPanel(RightSideBarPanels.ISSUES);
                    break;
                case 'importSets':
                    setCurrentPanel(RightSideBarPanels.IMPORT_SETS);
                    break;
                default:
                    setCurrentPanel(RightSideBarPanels.NONE);
                    break;
            }

            props.onSidebarChange(null, panel !== null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, props.isLoading]);

    const getIssues = useCallback(() => {
        return <ToggleButton disabled={issuesDisabled} id={'issues'} value="issues" classes={{ root: styles.button, selected: styles.buttonSelected }}>
            <FontAwesomeIcon icon={faWarning} fontSize={32} />
            <Typography variant="caption" classes={{ root: styles.buttonText }}>
                {t('RightNavBar.Issues')}
            </Typography>
        </ToggleButton>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issuesDisabled]);

    return (
        <Box className={styles['sidebar-container']}>
            <PropertiesPanel
                open={currentPanel === RightSideBarPanels.PROPERTIES}
                onClose={handlePanelClose}
                selection={props.selection}
                selectionMode={props.selectionMode}
                multiSelect={props.multiSelect}
            />
            <IssuesPanel
                open={currentPanel === RightSideBarPanels.ISSUES && !issuesDisabled}
                onClose={handlePanelClose}
                onDelete={props.handleDeleteIssue}
                issues={props.issues}
                selection={props.selection}
                viewer={props.modelviewer}
                projectId={props.projectId}
            />
            <ImportSetsPanel
                open={(currentPanel === RightSideBarPanels.IMPORT_SETS)}
                onClose={handlePanelClose}
                selection={props.selection}
                projectId={props.projectId}
                viewer={props.modelviewer}
            />
            <Box className={styles['button-container']}>
                <Indicator anchor={anchor.current} side="right" />
                <ToggleButtonGroup ref={buttonGroup} value={currentPanel} exclusive orientation="vertical" onChange={handleTab}>
                    <ToggleButton
                        disabled={!!props.isLoading}
                        id={'properties'}
                        value="properties"
                        classes={{ root: styles.button, selected: styles.buttonSelected }}>
                        <FontAwesomeIcon icon={faBarsStaggered} fontSize={28} />
                        <Typography variant="caption" classes={{ root: styles.buttonText }}>
                            {t('RightNavBar.Properties')}
                        </Typography>
                    </ToggleButton>
                    {issuesDisabled ? <Tooltip title={t('RightNavBar.NotEnabledForPolicy')}>
                        <div className={styles['wrapper-container']}>
                            {getIssues()}
                        </div>
                    </Tooltip>
                        : getIssues()
                    }
                    <ToggleButton
                        disabled={props.isLoading}
                        id={'importSets'}
                        value="importSets"
                        classes={{ root: styles.button, selected: styles.buttonSelected }}>
                        <FontAwesomeIcon icon={faCubesStacked} fontSize={32} />
                        <Typography variant="caption" classes={{ root: styles.buttonText }}>
                            {t('RightNavBar.ImportSets')}
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}
